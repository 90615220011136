
import Component from "vue-class-component";
import Vue from "vue";
import { strategyOptimizerApi } from "@/plugins/api";
import { StrategyOptimize } from "api/models";
import StrategyOptimizeStatusEnum from "@/entity/map/StrategyOptimizeStatusEnum";
import { mdiDotsVertical, mdiPencil, mdiTrashCanOutline } from "@mdi/js";

@Component({})
export default class Test extends Vue {
  from = "2019-07-01";
  to = "2020-12-28";
  candleSize = "15m";

  strategyOptimizeList: Array<StrategyOptimize> = [];

  icons = {
    mdiDotsVertical: mdiDotsVertical,
    mdiTrashCanOutline: mdiTrashCanOutline,
    mdiPencil: mdiPencil,
  };

  created() {
    this.getAll();
  }

  getAll() {
    return strategyOptimizerApi.getAppStrategyoptimizerGetall().subscribe((next) => (this.strategyOptimizeList = next));
  }

  getHeaders(): Array<{ text: string; value: string }> {
    return [
      { text: "#", value: "id" },
      { text: "Status", value: "status" },
      { text: "Candle size", value: "candleSize" },
      { text: "From", value: "fromDate" },
      { text: "To", value: "toDate" },
      { text: "Actions", value: "actions" },
    ];
  }

  getStatusText(status) {
    return StrategyOptimizeStatusEnum.TEXTS[status];
  }

  navigate(item) {
    this.$router.push({
      name: "strategyOptimizer",
      params: { strategyOptimizeId: item.id },
    });
  }

  addNew() {
    this.$router.push({
      name: "strategyOptimizerNew",
    });
  }

  editItem(item) {
    this.$router.push({
      name: "strategyOptimizerEdit",
      params: {
        strategyOptimizeId: item.id,
      },
    });
  }

  deleteItem(item) {
    if (confirm("Do you really want to delete this?")) {
      strategyOptimizerApi
        .deleteAppStrategyoptimizerRemove({
          strategyOptimizeId: item.id,
        })
        .subscribe(() => {
          this.getAll();
        });
    }
  }
}
